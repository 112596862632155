<template>
    <PointBase :loader_prop="loader_prop">
        <div class="add_customer_banner" v-if= "add_customer_screen == true">
            <div class="comman_banner">
                <h2>הוספת לקוח קצה</h2>
            </div>
            <div class="container">
                <div class="singup_page">
                    <form @submit="add" class="singup_form">
                        <p class="error_text" >{{errs}}</p>
                        <div class="form-group">
                            <label>שם הלקוח</label>
                            <input type="text" class="form-control" v-model="name">
                        </div>
                        <div class="form-group">
                            <label>מספר טלפון</label>
                            <input type="text" class="form-control" v-model="phone_number">
                        </div>
                        <div class="form-group">
                            <label>כתובת מייל</label>
                            <input type="text" class="form-control" v-model="email">
                        </div>
                        <input type="submit" class="comman_anchor" value="שמירה">
                    </form>
                </div>
            </div>
        </div>
        <div class="package_section thankyou_section" v-if = "thankyou == true">
            <div class="container">
                <div class="table_wrap">
                    <div class="cell_wrap">
                        <div class="empty_package">
                            <img src="images/thankyou_page_box.png" alt="Thank You">
                            <p>תודה, הלקוח נשמר :)</p>
                            <router-link :to="{path : '/point/point_customer'}" class="comman_anchor">למסך הבית </router-link>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PointBase> 
</template>
<script>
    import BackendModel from "../../models/BackendModel";
    import PointBase from "./PointBase";
    import('./point/css/style.css')
    export default {
      name: 'PointAddCustomer',
      components: {
        PointBase
      },
      data() {
        return {
            name : '',
            phone_number : '',
            email : '',
            errs:'',
            thankyou : false,
            add_customer_screen : true,
            loader_prop : false,
        }
      },

      async mounted() {
         this.errs = ''
      },
      
      methods : {

        async add(e) {
            e.preventDefault()
            let phone_number = this.phone_number.replaceAll('-','')
            const backendModel = new BackendModel()
            let res = await backendModel.point_Request('/Api/service/backoffice/point_add_customer', {"name" : this.name , "phone_number" : phone_number ,"email" : this.email} );
             if (res.data.res == "NOK" ){
                this.errs = 'אופס, שכחת משהו'
            }
            if(res.data.res == "NOK" && res.data.err =="DUPLICATE"){
                this.errs = 'הלקוח רשום'
            }
            if(res.data.res == "OK" ){
                this.thankyou = true
                this.add_customer_screen = false
                this.loader_prop = false
            // await this.$router.push({ path: '/point/point_customer' })  
            }
        }

      }
    }

</script>

<style>
@import 'point/css/style.css';
</style>